var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mile-history-card" }, [
    _c(
      "div",
      { staticClass: "mile-history-card__icon" },
      [_c("Icon", { attrs: { name: _vm.icon } })],
      1
    ),
    _c("div", { staticClass: "mile-history-card__content" }, [
      _c("div", { staticClass: "mile-history-card__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { staticClass: "mile-history-card__time" }, [
        _vm._v(_vm._s(_vm.time)),
      ]),
    ]),
    _c("div", { staticClass: "mile-history-card__mile" }, [
      _vm._v("+" + _vm._s(_vm.mile)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
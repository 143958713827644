<template>
  <div class="mile-history-card">
    <div class="mile-history-card__icon">
      <Icon :name="icon" />
    </div>

    <div class="mile-history-card__content">
      <div class="mile-history-card__title">{{ title }}</div>
      <div class="mile-history-card__time">{{ time }}</div>
    </div>

    <div class="mile-history-card__mile">+{{ mile }}</div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },

    time: {
      type: String,
      default: ''
    },

    mile: {
      type: [Number, String],
      required: true
    },

    icon: {
      type: String,
      default: 'mileOn'
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';
.mile-history-card {
  &__icon {
    flex: 0 0 56px;
    height: 56px;
    display: grid;
    place-items: center;
    background: variables.$grey04;
    border-radius: 999px;

    .c-icon {
      width: 32px;
      height: 32px;

      g {
        fill: variables.$grey01;
      }
    }
  }

  &__content {
    flex: 1 1 100%;
    padding: 0 1rem 0 1.6rem;

    .mile-history-card__title {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2rem;
      margin-bottom: 0.2rem;
    }

    .mile-history-card__time {
      font-size: 1.3rem;
      color: variables.$grey01;
    }
  }

  &__mile {
    flex: 1 0 auto;
    font-size: 2.4rem;
    font-weight: bold;
    color: variables.$secondary01;
  }
}
</style>
